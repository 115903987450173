import {Formik} from "formik";
import React, {RefObject} from "react";
import {Panel} from "primereact/panel";
import AppDateRangePicker from "../../../utils/AppDateRangePicker";


export interface IMonitoringSTTFilterContainerValue {
  period?: any;
}

export class MonitoringSTTFilterContainer extends React.PureComponent<
  any,
  any
> {
  initialFormValues: IMonitoringSTTFilterContainerValue = {
    period: [new Date(), new Date()],
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel
        header='Filter'
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({filterCollapsed: e.value})}
      >
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => {}}
        >
          {(formikProps) => (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 p-4">
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Periode</label>
                  <div className="flex-1">
                    <AppDateRangePicker
                      name='period'
                      onChange={formikProps.handleChange("period")}
                      value={formikProps.values.period}
                      showButtonBar={true}
                      clearButtonStyleClass='p-button-danger'
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
