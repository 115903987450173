import { observer } from "mobx-react";
import { Button } from "primereact/button";
import React, { RefObject } from 'react';
import { Flex } from "reflexbox";
import { TabView, TabPanel } from 'primereact/tabview';

import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { MonitoringSTTFilterContainer, IMonitoringSTTFilterContainerValue } from "./MonitoringSTTFilterContainer";
import moment from "moment";
import { Growl } from "primereact/growl";

@observer
export class MonitoringSTTContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl;
  formFilters: IMonitoringSTTFilterContainerValue;
  refFilter: RefObject<
  MonitoringSTTFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      dashboardId: 2717,
      showNote: false,
      dashboards: {
        url: '',
        token: '',
      },
      isHeadQuarter: localStorage.getItem("branchCode") === "3601001"
    };
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  onFilterData() {
    this.setState({
      dashboards: {
        url: "",
        token: "",
      }
    })
    this.loadData();
  }

  async loadData() {
    const formValue = this.refFilter.current!.formValues
    let startDate;
    let endDate;
    const { period } = formValue;
    startDate = period[0];
    endDate = period[1] ? period[1] : period[0];
    const params = {
      ...(startDate ? { start_date: moment(startDate).format("YYYY-MM-DD") } : {}),
      ...(endDate ? { end_date: moment(endDate).format("YYYY-MM-DD") } : {}),
    }

    this.monitoringRestService
    .get(`embed-monpickup/${this.state.dashboardId}`, { params })
    .subscribe(
      (response) => {
        this.setState({
          dashboards: {
            url: response.url,
            token: response.token,
          }
        })
      },
      (e) => {
        let msg = {
          severity: "error",
          summary: e.response.data['message'],
        };
        this.growl.show(msg);
      },
      () => {}
    );
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Monitoring STT</h1>
            <MonitoringSTTFilterContainer ref={this.refFilter} isHeadQuerter={this.state.isHeadQuarter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.onFilterData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          {this.state.dashboards.url && this.state.dashboards.token && (
            <iframe
              src={this.state.dashboards.url}
              width={"100%"}
              height={750}
              allowTransparency
            />
          )}
        </div>
      </div>
    );
  }
}
