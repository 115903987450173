import { observer } from 'mobx-react';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import qs from 'qs';
import React, { RefObject } from 'react';
import { RouterProps } from 'react-router';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbDetailModalComponent } from './../awb/AwbDetailModalComponent';
import { MM_BRANCH_TYPE_OPTIONS } from './constants';
import { observable } from 'mobx';
import { Button } from 'primereact/button';

export interface IMonitoringMMDetailContainerQueryNew {
  field: string;
  label: string;
  filters: {
    date?: string;
    city?: string;
    representative?: string;
    service?: string;
    cod?: string;
    branchType?: string;
  };
  trackingSiteCode: string;
  trackingSite: number;
}

@observer
export class MonitoringMMDetailContainer extends React.Component<RouterProps> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  @observable data;
  tableExportData;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  pageTitle1: string;
  pageTitle2: string;
  queryParameters: IMonitoringMMDetailContainerQueryNew;

  constructor(props, context) {
    super(props, context);
    this.export = this.export.bind(this);
  }

  componentWillMount() {
    this.queryParameters = qs.parse(
      this.props.history.location.search.substr(1),
    ) as any;

    this.pageTitle1 = `${this.queryParameters.trackingSite} | ${this.queryParameters.label}`;

    const { date, city, representative, service, cod, branchType } = this.queryParameters.filters;
    const pageTitle2Elements: string[] = [];
    if (date) {
      const formattedDate = moment(date).format(
        'DD-MM-YYYY',
      );
      pageTitle2Elements.push(formattedDate);
    }
    if (city) {
      pageTitle2Elements.push(city);
    }
    if (representative) {
      pageTitle2Elements.push(representative);
    }
    if (service) {
      pageTitle2Elements.push(service);
    }
    if (cod) {
      if (cod === "true") pageTitle2Elements.push("COD");
      else if (cod === "false") pageTitle2Elements.push("NON COD");
    }
    if (branchType) {
      const branchTypeLabel = MM_BRANCH_TYPE_OPTIONS.find((type) => type.value === branchType)
      if (branchTypeLabel) {
        pageTitle2Elements.push(branchTypeLabel.label);
      }
    }
    this.pageTitle2 = pageTitle2Elements.join('/');

    this.loadData();
  }

  get queryParametersTypeId() {
    const columnFieldNames = {
      date: "CALCULATE_DATE",
      trackingSiteCity: "CITY_NAME",
      trackingSite: "BRANCH_NAME",
      trackingSiteCode: "BRANCH_CODE",
      perwakilan:	"REPRESENTATIVE_CODE",
      prevOpenStatus: "IS_PREV_PROCESS",
      do: "IS_DO",
      in: "IS_IN",
      total: "TOTAL",
      out: "IS_OUT",
      nob: "IS_NOB",
      notSo: "NOT_SO",
      doneSo: "DONE_SO",
      overSo: "MORE_SO",
      problem: "IS_PROBLEM",
    }
    return columnFieldNames[this.queryParameters.field] ? columnFieldNames[this.queryParameters.field] : "";
  }
  
  loadData() {
    this.dataSource.loading = true;
    const { date, city = "", representative = "", service = "", cod, branchType = "" } = this.queryParameters.filters
    let codValue: any = cod
    if (cod === "true") codValue = true;
    else if (cod === "false") codValue = false;
    else if (cod === "") codValue = null;
    this.monitoringRestService
      .post('monitoring-mm/detail/all', {
        date: date,
        city_name: city,
        representative_code: representative,
        layanan: service,
        is_cod: codValue,
        type_branch_name: branchType,
        branch_code: this.queryParameters.trackingSiteCode,
        detail_type: this.queryParametersTypeId,
        sort_by: "awb_number",
      })
      .subscribe(
        response => {
          const mappedData = response.map((d) => {
            return {
              ...d,     
              receiptNumber: d.AWB_NUMBER,
              transactionDate: d.AWB_DATE,
              xxx: d.LM_PRIORITY_LEVEL,
              origin: d.FROM_DISTRICT_CODE,
              asalKota: d.FROM_CITY_NAME,
              asalKecamatan: d.FROM_DISTRICT_NAME,
              destination: d.TO_DISTRICT_CODE,
              tujuanKota: d.TO_CITY_NAME,
              tujuanKecamatan: d.TO_DISTRICT_NAME,
              pengirim: d.PENGIRIM,
              recipient: d.CONSIGNEE_NAME,
              recipientPhone: d.CONSIGNEE_PHONE,
              perwakilan: d.REPRESENTATIVE_CODE,
              layanan: d.LAYANAN,
              codValue: d.COD_VALUE,
              beratasli: d.TOTAL_WEIGHT_REAL,
              leadTime: d.USIA_PAKET,
              overSLADaysInternal: d.OVER_SLA_DAYS,
              slaMaxDateTimeInternal: d.SLA_MAX_DATE,
              statusTrackingType: d.PREV_AWB_STATUS_NAME,
              statusTrackingSite: d.PREV_BRANCH_NAME,
              statusDate: d.PREV_DATE_TIME,
              statusNote: d.PREV_NOTE_INTERNAL,
              lastStatusTrackingType: d.LAST_MILES_AWB_STATUS_NAME,
              lastStatusTrackingSite: d.LAST_MILES_BRANCH_NAME,
              lastStatusDate: d.LAST_MILES_DATE_TIME,
              lastStatusNote: d.LAST_MILES_NOTE_INTERNAL,
            }
          })
          this.data = mappedData;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  }

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>{rowData[column.field!]}</a>
    );
  }

  export() {
    this.tableExportData.exportCSV();
  }
  
  statusDetail = ({ date, trackingSite = "-", note = "-" }: { date: string, trackingSite: string, note: string}) => {
    const formattedDate = date ? moment(date).format('DD-MM-YYYY HH:mm') : "-"
    return (
      <div>
        <div><b>Cabang:</b> {trackingSite}</div>
        <div><b>Tanggal:</b> {formattedDate}</div>
        <div><b>Note: </b><small>{note}</small></div>
      </div>
    );
  }

  render() {
    var header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div className="card">
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <h3>
          {this.pageTitle1} - {this.pageTitle2}
        </h3>

        <DataTable
          emptyMessage={'Tidak ada data untuk ditampilkan.'}
          scrollable={true}
          scrollHeight="550px"
          className="mt-6"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
          footer={footer}
          header={header} ref={(el) => { this.tableExportData = el; }}>
          <Column
            field="receiptNumber"
            header="No Resi"
            filter={true}
            sortable={true}
            style={{ width: '130px', "vertical-align": 'text-top' }}
            body={this.columnAwbNumber}
          />
          <Column
            field="transactionDate"
            header="Tanggal Transaksi"
            sortable={true}
            style={{ width: '145px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              if (value) {
                const d = moment(value);
                if (d.isValid()) {
                  value = d.format('DD-MM-YYYY');
                } else {
                  value = "-"
                }
              }

              return value;
            }}
          />
          <Column
            field="origin"
            header="Asal"
            sortable={true}
            filterMatchMode="contains"
            style={{ width: '120px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let originCode = rowData[column.field!];
              let originKota = rowData['asalKota'];
              let originKecamatan = rowData['asalKecamatan'];

              return (
                <div>
                  <div><b>{originCode}</b></div>
                  <div><small>{originKota} ({originKecamatan})</small></div>
                </div>
              );
            }}
          />
          <Column
            field="destination"
            header="Tujuan"
            sortable={true}
            filterMatchMode="contains"
            style={{ width: '120px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let destinationCode = rowData[column.field!];
              let destinationKota = rowData['tujuanKota'];

              return (
                <div>
                  <div><b>{destinationCode}</b></div>
                  <div><small>{destinationKota}</small></div>
                </div>
              );
            }}
          />
          <Column
            field="tujuanKecamatan"
            header="Tujuan Kecamatan"
            filterMatchMode="contains"
            style={{ width: '150px', "vertical-align": 'text-top' }}
          />
          <Column
            field="pengirim"
            header="Pengirim"
            filterMatchMode="contains"
            style={{ width: '180px', "vertical-align": 'text-top' }}
          />
          <Column
            field="recipient"
            header="Penerima"
            filterMatchMode="contains"
            style={{ width: '180px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              let value = rowData[column.field!];
              const phone = rowData['recipientPhone'];

              if (phone) {
                return <div><div>{value}</div><div><small style={{ wordWrap: "break-word"}}>{phone}</small></div></div>
              } else {
                return value;
              }
            }}
          />
          <Column
            field="perwakilan"
            header="Perwakilan"
            filterMatchMode="contains"
            style={{ width: '100px', "vertical-align": 'text-top' }}
          />
          <Column
            field="layanan"
            header="Layanan"
            style={{ width: '100px', "vertical-align": 'text-top' }}
          />
          <Column
            field="codValue"
            header="Nilai COD"
            style={{ width: '100px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              const colValue = rowData[column.field!];
              const fmtValue = (colValue) ? rowData[column.field!].toLocaleString() : '-';
              return <span>{fmtValue}</span>
            }}
          />
          <Column
            field="beratasli"
            header="Berat Asli"
            style={{ width: '100px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              const colValue = rowData[column.field!];
              return <span>{colValue} kg</span>
            }}
          />
          <Column
            field="leadTime"
            header="Usia Paket"
            style={{ width: '100px', "vertical-align": 'text-top' }}
          />
          <Column
            field="overSLADaysInternal"
            header="Over SLA"
            style={{ width: '120px', "vertical-align": 'text-top' }}
            body={(rowData, column: ColumnProps) => {
              const tglOverSla = moment(rowData['slaMaxDateTimeInternal']);
              let sTglOverSla: string = '-';
              if (tglOverSla.isValid()) {
                sTglOverSla = tglOverSla.format('DD-MM-YYYY');
              }
              const overSlaDays = rowData[column.field!];

              return (
                <div>
                  <div><b>{overSlaDays} hari</b></div>
                  <div><small>{sTglOverSla}</small></div>
                </div>
              )
            }}
          />
          <Column
            field="statusTrackingType"
            header="Status Akhir Middle Mile"
            style={{ width: '150px', "vertical-align": 'text-top' }}
          />
          <Column
            field="statusDate"
            header="Info Status Akhir Middle Mile"
            style={{ width: '250px', "vertical-align": 'text-top', }}
            body={(rowData) => {
              return this.statusDetail({
                date: rowData["statusDate"],
                trackingSite: rowData['statusTrackingSite'],
                note: rowData['statusNote'],
              });
            }}
          />
          <Column
            field="statusTrackingSite"
            header="Info Status Akhir Middle Mile"
            style={{ width: '250px', "vertical-align": 'text-top', display:'none' }}
          />
          <Column
            field="statusNote"
            header="Info Status Akhir Middle Mile"
            style={{ width: '250px', "vertical-align": 'text-top', display:'none' }}
          />
          <Column
            field="lastStatusTrackingType"
            header="Status Akhir"
            style={{ width: '150px', "vertical-align": 'text-top' }}
          />
          <Column
            field="lastStatusDate"
            header="Info Status Akhir"
            style={{ width: '250px', "vertical-align": 'text-top' }}
            body={(rowData) => {
              return this.statusDetail({
                date: rowData["lastStatusDate"],
                trackingSite: rowData['lastStatusTrackingSite'],
                note: rowData['lastStatusNote'],
              });
            }}
          />
          <Column
            field="lastStatusTrackingSite"
            header="Info Status Akhir"
            style={{ width: '250px', "vertical-align": 'text-top', display:'none' }}
          />
          <Column
            field="lastStatusNote"
            header="Info Status Akhir"
            style={{ width: '250px', "vertical-align": 'text-top', display:'none' }}
          />
        </DataTable>
      </div>
    );
  }
}
