import { observer } from "mobx-react";
import { Button } from "primereact/button";
import React, { RefObject } from 'react';
import { Flex } from "reflexbox";
import { TabView, TabPanel } from 'primereact/tabview';

import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { MonitoringSlaPickupFilterContainer, IMonitoringSlaPickupFilterContainerValue } from "./MonitoringSlaPickupFilterContainer";
import moment from "moment";
import { Growl } from "primereact/growl";

@observer
export class MonitoringSlaPickupContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl;
  formFilters: IMonitoringSlaPickupFilterContainerValue;
  refFilter: RefObject<
  MonitoringSlaPickupFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      dashboardId: 2551,
      showNote: false,
      dashboards: [
        {
          value: 2551,
          label: "Dashboard",
          url: "",
          token: "",
        },
        {
          value: 2573,
          label: "Total Resi",
          url: "",
          token: "",
        },
        {
          value: 2572,
          label: "On SLA",
          url: "",
          token: "",
        },
        {
          value: 2569,
          label: "Achieve",
          url: "",
          token: "",
        },
        {
          value: 2570,
          label: "Breach Internal",
          url: "",
          token: "",
        },
        {
          value: 2571,
          label: "Breach External",
          url: "",
          token: "",
        },
      ],
      isHeadQuarter: localStorage.getItem("branchCode") === "3601001"
    };
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  onFilterData() {
    const dashboardList = this.state.dashboards.map((data) => {
      return {
        ...data,
        url: "",
        token: "",
      }
    })
    this.setState({
      dashboards: dashboardList
    })
    this.loadData();
  }

  async loadData(dashboardId = this.state.dashboardId) {
    const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
    const { branch_name } = userData;
    const formValue = this.refFilter.current!.formValues
    const { period, branch = [], partner = [] } = formValue;
    let startDate;
    let endDate;
    startDate = period[0];
    endDate = period[1] ? period[1] : period[0];
    const params = {
      ...(startDate ? { start_date: moment(startDate).format("YYYY-MM-DD") } : {}),
      ...(endDate ? { end_date: moment(endDate).format("YYYY-MM-DD") } : {}),
      partner: partner.map((data) => data.value),
      branch_name: branch.length ? branch.map((data) => data.value) : [branch_name],
    }

    this.monitoringRestService
    .get(`embed-monpickup/${dashboardId}`, { params })
    .subscribe(
      (response) => {
        const dashboardList = this.state.dashboards.map((dashboard) => {
          if (dashboard.value === dashboardId) {
            return {
              ...dashboard,
              url: response.url,
              token: response.token,
            }
          } else {
            return dashboard
          }
        })
        this.setState({
          dashboards: dashboardList
        })
      },
      (e) => {
        let msg = {
          severity: "error",
          summary: e.response.data['message'],
        };
        this.growl.show(msg);
      },
      () => {}
    );
  }

  onChangeDashboard = (value) => {
    const selectedDashboard = this.state.dashboards[value.index];
    this.setState({
      activeIndex: value.index,
      dashboardId: selectedDashboard.value
    });
    if (selectedDashboard.url === "" || selectedDashboard.token === "") {
      this.loadData(selectedDashboard.value);
    }
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Monitoring SLA Pickup</h1>
            <MonitoringSlaPickupFilterContainer ref={this.refFilter} isHeadQuerter={this.state.isHeadQuarter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.onFilterData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onChangeDashboard(e)} renderActiveOnly={false}>
            {this.state.dashboards.map((dashboard) => (
              <TabPanel key={dashboard.value} header={dashboard.label}>
                <>
                  {dashboard.url && dashboard.token && (
                    <iframe
                      src={dashboard.url}
                      width={"100%"}
                      height={750}
                      allowTransparency
                    />
                  )}
                </>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    );
  }
}
