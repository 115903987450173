import { observer } from "mobx-react";
import { Button } from "primereact/button";
import React, { RefObject } from 'react';
import { Flex } from "reflexbox";
import { TabView, TabPanel } from 'primereact/tabview';
import { Panel } from "primereact/panel";

import { MonitoringRestService } from "../../../services/MonitoringRestService";
import { DependencyContainer } from "../../../utils/DependencyInjection";
import { MonitoringProblemFilterContainer, IMonitoringProblemFilterContainerValue } from "./MonitoringProblemFilterContainer";
import moment from "moment";
import { Growl } from "primereact/growl";


@observer
export class MonitoringProblemContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl;
  formFilters: IMonitoringProblemFilterContainerValue;
  refFilter: RefObject<
  MonitoringProblemFilterContainer
  > = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      dashboardId: 2822,
      showNote: false,
      dashboards: [
        {
          value: 2822,
          label: "Home",
          url: '',
          token: '',
        },
        {
          value: 2830,
          label: "Paket Unpick",
          url: '',
          token: '',
        },
        {
          value: 2830,
          label: "Paket Unpick Reschedule",
          url: '',
          token: '',
        },
        {
          value: 2830,
          label: "Paket Unpick Not Reschedule",
          url: '',
          token: '',
        },
        {
          value: 2829,
          label: "Paket Tanpa Status",
          url: '',
          token: '',
        },
        {
          value: 2827,
          label: "Paket Pick/Drop Non Manifest",
          url: '',
          token: '',
        },
        {
          value: 2826,
          label: "Paket Pending (XXX)",
          url: '',
          token: '',
        },
        {
          value: 2823,
          label: "Best Overtime",
          url: '',
          token: '',
        },
        {
          value: 2824,
          label: "Not Outbound",
          url: '',
          token: '',
        },
        {
          value: 2828,
          label: "Paket Stuck",
          url: '',
          token: '',
        },
      ],
      isHeadQuarter: localStorage.getItem("branchCode") === "3601001"
    };
  }
  
  componentDidMount() {
    this.loadData();
  }
  
  onFilterData() {
    const dashboardList = this.state.dashboards.map((data) => {
      return {
        ...data,
        url: "",
        token: "",
      }
    })
    this.setState({
      dashboards: dashboardList
    })
    this.loadData();
  }

  async loadData(dashboardId = this.state.dashboardId, activeTabIndex = this.state.activeIndex) {
    const userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;
    const formValue = this.refFilter.current!.formValues
    const { branch_code } = userData;
    const { reqPickupPeriod, manifestPeriod, pickDropPeriod } = formValue;
    let params: any = {
      branch_code
    }
    if (reqPickupPeriod) {
      const startDate = reqPickupPeriod[0];
      const endDate = reqPickupPeriod[1] ? reqPickupPeriod[1] : reqPickupPeriod[0];
      params.tgl_req_pickup = `${moment(startDate).format("YYYY-MM-DD")}~${moment(endDate).format("YYYY-MM-DD")}`
    }
    if (manifestPeriod) {
      const startDate = manifestPeriod[0];
      const endDate = manifestPeriod[1] ? manifestPeriod[1] : manifestPeriod[0];
      params.tgl_manifest = `${moment(startDate).format("YYYY-MM-DD")}~${moment(endDate).format("YYYY-MM-DD")}`
    }
    if (pickDropPeriod) {
      const startDate = pickDropPeriod[0];
      const endDate = pickDropPeriod[1] ? pickDropPeriod[1] : pickDropPeriod[0];
      params.tgl_pick_or_drop = `${moment(startDate).format("YYYY-MM-DD")}~${moment(endDate).format("YYYY-MM-DD")}`
    }

    const dashboards = this.state.dashboards
    let activeTab = dashboards[activeTabIndex]
    if (activeTab.label === 'Paket Unpick Reschedule') {
      params.reschedule = 'Ya'
    }
    if (activeTab.label === 'Paket Unpick Not Reschedule') {
      params.reschedule = 'Tidak'
    }

    this.monitoringRestService
    .get(`embed-monpickup/${dashboardId}`, { params })
    .subscribe(
      (response) => {
        dashboards[this.state.activeIndex] = {
          ...dashboards[this.state.activeIndex],
          url: response.url,
          token: response.token,
        }
        this.setState({
          dashboards: dashboards
        })
      },
      (e) => {
        let msg = {
          severity: "error",
          summary: e.response.data['message'],
        };
        this.growl.show(msg);
      },
      () => {}
    );
  }

  onChangeDashboard = (value) => {
    const selectedDashboard = this.state.dashboards[value.index];
    this.setState({
      activeIndex: value.index,
      dashboardId: selectedDashboard.value
    });
    if (selectedDashboard.url === "" || selectedDashboard.token === "") {
      this.loadData(selectedDashboard.value, value.index);
    }
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <h1>Monitoring Problem</h1>
            <MonitoringProblemFilterContainer ref={this.refFilter} isHeadQuerter={this.state.isHeadQuarter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.onFilterData()}
              />
            </Flex>
          </Flex>
        </Flex>

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <Panel header="Note">
              <ul style={{ listStyle: "initial", paddingLeft: "1.5em" }}>
                <li>Cara <b>Melihat List Resi</b>: Harap klik <b>Tab</b> dibawah ini untuk melihat <b>List Resi</b></li>
                <li>
                  Cara <b>Download</b> Data Resi :
                  <ul style={{ listStyle: "circle", paddingLeft: "1.5em" }}>
                    <li>Pilih <b>Tab</b> yang akan dilihat</li>
                    <li>Arahkan <b>Cursor/Panah</b> ke tabel</li>
                    <li>
                      <Flex row>
                        Klik Icon
                        <svg
                          style={{ color: "#b8bbc3" }}
                          className="Icon Icon-download e621b520 css-4g44w1 e621b521 ml-2"
                          viewBox="0 0 52 32"
                          width="20"
                          height="20"
                          fill="currentcolor"
                          role="img"
                          aria-label="download icon"
                        >
                          <path d="M26 0c7.264 0 13.323 5.164 14.704 12.022C47.004 12.384 52 17.609 52 24c0 6.627-5.373 12-12 12H12C5.373 36 0 30.627 0 24c0-6.391 4.997-11.616 11.297-11.98C12.677 5.164 18.736 0 26 0zm0 6a3 3 0 00-3 3v13.649l-5.232-5.511-.13-.128a2.409 2.409 0 00-3.406.128c-.976 1.028-.976 2.696 0 3.724l10 10.533.13.129a2.409 2.409 0 003.406-.129l10-10.533.122-.138c.852-1.034.812-2.602-.122-3.586l-.13-.128a2.409 2.409 0 00-3.406.128L29 22.649V9a3 3 0 00-3-3z"></path>
                        </svg>
                      </Flex>
                    </li>
                  </ul>
                </li>
                <li>Untuk <b>Kembali </b>ke Halaman Awal, Klik  <b>Tab “Home”</b></li>
              </ul>
            </Panel>
          </Flex>
        </Flex>

        <div className="card">
          <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.onChangeDashboard(e)} renderActiveOnly={false}>
            {this.state.dashboards.map((dashboard) => (
              <TabPanel key={dashboard.value} header={dashboard.label}>
                <>
                  {dashboard.url && dashboard.token && (
                    <iframe
                      src={dashboard.url}
                      width={"100%"}
                      height={750}
                      allowTransparency
                    />
                  )}
                </>
              </TabPanel>
            ))}
          </TabView>
        </div>
      </div>
    );
  }
}
