import { observer } from 'mobx-react';
import { Fieldset } from 'primereact/fieldset';
import qs from 'qs';
import React from 'react';
import { Link } from 'react-router-dom';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { getGeneralSummary, getUnpickSummary, summaryList } from './TimeWindowSummaryList';

@observer
export class TimeWindowSummaryContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  filterParams;

  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoadingSummary: false,
      isLoadingSummaryUnpick: false,
      rpuTodayCollapsed: false,
      spkYesterdayCollapsed: false,
      spkCollapsed: false,
      rpuPlanCollapsed: false,
      actualPlanCollapsed: false,
      unpickRescheduleCollapsed: false,
      unpickNotRescheduleCollapsed: false,
      rpuToday: null,
      spkYesterday: null,
      spk: null,
      rpuPlan: null,
      actualPlan: null,
      unpickReschedule: null,
      unpickNotReschedule: null,
    };
  }

  setSummaryData(data) {
    const generalSummary = getGeneralSummary(data)
    const { rpuToday = [], spkYesterday = [], spk = [], rpuPlan = [], actualPlan = [] } = generalSummary
    this.setState({
      rpuToday: rpuToday,
      spkYesterday: spkYesterday,
      spk: spk,
      rpuPlan: rpuPlan,
      actualPlan: actualPlan,
    })
  }
  
  setSummaryUnpickData(data) {
    const unpickSummary = getUnpickSummary(data)
    const { unpickReschedule = [], unpickNotReschedule = [] } = unpickSummary

    this.setState({
      unpickReschedule: unpickReschedule,
      unpickNotReschedule: unpickNotReschedule,
    })
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;

    this.setState({
      isLoadingSummary: true,
      rpuToday: null,
      spkYesterday: null,
      spk: null,
      rpuPlan: null,
      actualPlan: null,
      unpickReschedule: null,
      unpickNotReschedule: null,
    });
    this.monitoringRestService
    .post("sf/time-window/summary", filterParams)
    .subscribe(
      response => {
        const d = response[0]
        const mappedData = {
          ...d,
          rpu: d.RPU,
          rpu_pickup: d.RPU_PICKUP,
          rpu_drop: d.RPU_DROP,
          rpu_pickup_today: d.RPU_PICKUP_TODAY,
          rpu_pickup_today_picked_all: d.RPU_PICKUP_TODAY_PICKED_ALL,
          rpu_pickup_today_picked: d.RPU_PICKUP_TODAY_PICKED,
          rpu_pickup_today_unpicked: d.RPU_PICKUP_TODAY_UNPICKED,
          rpu_pickup_today_unpicked_reschedule: d.RPU_PICKUP_TODAY_UNPICKED_RESCHEDULE,
          rpu_pickup_today_unpicked_not_reschedule: d.RPU_PICKUP_TODAY_UNPICKED_NOT_RESCHEDULE,
          rpu_pickup_today_not_pop: d.RPU_PICKUP_TODAY_NOT_POP,
          rpu_pickup_today_not_assigned: d.RPU_PICKUP_TODAY_NOT_ASSIGNED,
          spk_yesterday: d.SPK_YESTERDAY,
          spk_yesterday_picked_all: d.SPK_YESTERDAY_PICKED_ALL,
          spk_yesterday_picked: d.SPK_YESTERDAY_PICKED,
          spk_yesterday_unpicked: d.SPK_YESTERDAY_UNPICKED,
          spk_yesterday_unpicked_reschedule: d.SPK_YESTERDAY_UNPICKED_RESCHEDULE,
          spk_yesterday_unpicked_not_reschedule: d.SPK_YESTERDAY_UNPICKED_NOT_RESCHEDULE,
          spk_yesterday_not_pop: d.SPK_YESTERDAY_NOT_POP,
          spk_yesterday_not_assigned: d.SPK_YESTERDAY_NOT_ASSIGNED,
          spk_today: d.SPK_TODAY,
          spk: d.SPK,
          assigned: d.ASSIGNED,
          not_assigned: d.NOT_ASSIGNED,
          picked_all: d.PICKED_ALL,
          picked: d.PICKED,
          unpicked: d.UNPICKED,
          unpicked_reschedule: d.UNPICKED_RESCHEDULE,
          unpicked_not_reschedule: d.UNPICKED_NOT_RESCHEDULE,
          auto_pop: d.AUTO_POP,
          dropped: d.DROPPED,
          manifested: d.MANIFESTED,
          pick_not_manifested: d.PICK_NOT_MANIFESTED
        }
        this.setSummaryData(mappedData)
      },
      () => {
        this.setState({ isLoadingSummary: false });
      },
      () => {
        this.setState({ isLoadingSummary: false });
      },
    );
    
    this.setState({ isLoadingSummaryUnpick: true });
    this.monitoringRestService
      .post("sf/time-window/summary/unpick", filterParams)
      .subscribe(
        response => {
          const { reschedules = [], unreschedules = [] } = response
          const mappedData = {
            reschedules: reschedules.map((d) => {
              return {
                ...d,
                unpicked_not_reschedule: d.UNPICKED_NOT_RESCHEDULE,
                reason_id: d.REASON_ID,
                reason_name: d.REASON_NAME,
                count: d.COUNT,
              }
            }),
            unreschedules: unreschedules.map((d) => {
              return {
                ...d,
                unpicked_not_reschedule: d.UNPICKED_NOT_RESCHEDULE,
                reason_id: d.REASON_ID,
                reason_name: d.REASON_NAME,
                count: d.COUNT,
              }
            }),
          }
          this.setSummaryUnpickData(mappedData)
        },
        () => {
          this.setState({ isLoadingSummaryUnpick: false });
        },
        () => {
          this.setState({ isLoadingSummaryUnpick: false });
        },
      );    
  }

  generalSummaryDetailLink = (customPeriod) => (data) => {
    return (
      <Link
        to={{
          pathname: '/admin/time-window/awb-detail',
          search: qs.stringify({
            status: data.filterValue,
            title: data.filterTitle,
            activeTabIndex: 1,
            filters: this.filterParams,
            customPeriod: customPeriod,
          }),
        }}
        target="_blank"
      >
        {data.total.toLocaleString()}
      </Link>
    )
  }
  
  unpickSummaryDetailLink = (data) => {
    return (
      <Link
        to={{
          pathname: '/admin/time-window/awb-detail',
          search: qs.stringify({
            status: data.filterValue,
            title: data.filterTitle,
            activeTabIndex: 1,
            filters: this.filterParams,
            unpickReasonId: data.reasonId,
          }),
        }}
        target="_blank"
      >
        {data.total.toLocaleString()}
      </Link>
    )
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-6">
            <div className="mt-10">
              <Fieldset legend="Request Pickup Hari ini" toggleable={true} collapsed={this.state.rpuTodayCollapsed} onToggle={(e) => this.setState({ rpuTodayCollapsed: e.value })}>
                {summaryList(this.state.rpuToday, this.generalSummaryDetailLink("rpu_pickup_today"), this.state.isLoadingSummary)}
              </Fieldset>
            </div>
            <div className="mt-10">
              <Fieldset legend="SPK Kemarin" toggleable={true} collapsed={this.state.spkYesterdayCollapsed} onToggle={(e) => this.setState({ spkYesterdayCollapsed: e.value })}>
                {summaryList(this.state.spkYesterday, this.generalSummaryDetailLink("spk_yesterday"), this.state.isLoadingSummary)}
              </Fieldset>
            </div>
            <div className="mt-10">
              <Fieldset legend="Detail SPK" toggleable={true} collapsed={this.state.spkCollapsed} onToggle={(e) => this.setState({ spkCollapsed: e.value })}>
                {summaryList(this.state.spk, this.generalSummaryDetailLink(null), this.state.isLoadingSummary)}
              </Fieldset>
            </div>
          </div>
          <div className="col-md-6">
            <div className="mt-10">
              <Fieldset legend="Request Pickup Plan" toggleable={true} collapsed={this.state.rpuPlanCollapsed} onToggle={(e) => this.setState({ rpuPlanCollapsed: e.value })}>
                {summaryList(this.state.rpuPlan, this.generalSummaryDetailLink(null), this.state.isLoadingSummary)}
              </Fieldset>
            </div>
            <div className="mt-10">
              <Fieldset legend="Actual Plan" toggleable={true} collapsed={this.state.actualPlanCollapsed} onToggle={(e) => this.setState({ actualPlanCollapsed: e.value })}>
                {summaryList(this.state.actualPlan, this.generalSummaryDetailLink(null), this.state.isLoadingSummary)}
              </Fieldset>
            </div>
            <div className="mt-10">
              <Fieldset legend="Unpick Reschedule" toggleable={true} collapsed={this.state.unpickRescheduleCollapsed} onToggle={(e) => this.setState({ unpickRescheduleCollapsed: e.value })}>
                {summaryList(this.state.unpickReschedule, this.unpickSummaryDetailLink, this.state.isLoadingSummaryUnpick)}
              </Fieldset>
            </div>
            <div className="mt-10">
              <Fieldset legend="Unpick Not Reschedule" toggleable={true} collapsed={this.state.unpickNotRescheduleCollapsed} onToggle={(e) => this.setState({ unpickNotRescheduleCollapsed: e.value })}>
                {summaryList(this.state.unpickNotReschedule, this.unpickSummaryDetailLink, this.state.isLoadingSummaryUnpick)}
              </Fieldset>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
