import { observer } from 'mobx-react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { Flex } from 'reflexbox';

import { DataSource } from '../../models/DataSource';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { MonitoringMMFilterContainer, IMonitoringMMFilterContainerValue } from './MonitoringMMFilterContainer';
import { IMonitoringMMDetailContainerQueryNew } from './MonitoringMMDetailContainer';

@observer
export class MonitoringMMContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  tableExportData;
  formFilters: IMonitoringMMFilterContainerValue;
  dataSource = new DataSource<any>();
  refFilter: RefObject<MonitoringMMFilterContainer> = React.createRef();

  constructor(props) {
    super(props);
    this.exportCsv = this.exportCsv.bind(this);
  }

  loadData() {
    this.formFilters = this.refFilter.current!.formValues;
    const { city, representative, service, cod, branchType } = this.formFilters
    this.dataSource.loading = true;
    const mappedParams = {
      date: moment(this.formFilters.date).format('YYYY-MM-DD'),
      city_name: city ? city.value : "",
      representative_code: representative ? representative.value : "",
      layanan: service ? service.value : "",
      is_cod: cod,
      type_branch_name: branchType || "",
      sort_by: "city_name"
    }
    this.monitoringRestService
      .post('monitoring-mm/all', mappedParams)
      .subscribe(
        response => {
          this.processData(response);
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  processData(data) {
    const mappedData = data.map((d) => {
      return {
        ...d,
        trackingSiteCity: d.CITY_NAME,
        trackingSite: d.BRANCH_NAME,
        trackingSiteCode: d.BRANCH_CODE,
        perwakilan:	d.REPRESENTATIVE_CODE,
        prevOpenStatus: d.IS_PREV_PROCESS,
        do: d.IS_DO,
        in: d.IS_IN,
        total: d.TOTAL,
        out: d.IS_OUT,
        nob: d.IS_NOB,
        notSo: d.NOT_SO,
        doneSo: d.DONE_SO,
        overSo: d.MORE_SO,
        problem: d.IS_PROBLEM,
      }
    })
        
    this.dataSource.setData(mappedData);
    this.dataSource.setTotal(mappedData.length);
  }

  async exportCsv() {
    await this.tableExportData.exportCSV();
    let msg = {
      severity: 'success',
      summary: 'Success Message',
      detail: 'Export Success'
    };
    this.growl.show(msg);
  }

  columnDetailLinkBody = (rowData, column: ColumnProps) => {
    const colValue = Number(rowData[column.field!]);
    const { city, representative, service, cod, branchType } = this.formFilters
    const formFilters = {
      date: moment(this.formFilters.date).format('YYYY-MM-DD'),
      city: city ? city.value : "",
      representative: representative ? representative.value : "",
      service: service ? service.value : "",
      cod: cod,
      branchType: branchType || "",
    };
    
    return colValue ? (
      <Link
        to={{
          pathname: '/admin/monitoring-mm/detail',
          search: qs.stringify({
            field: column.field,
            label: column.header,
            filters: formFilters,
            trackingSiteCode: rowData.trackingSiteCode,
            trackingSite: rowData.trackingSite,
          } as IMonitoringMMDetailContainerQueryNew),
        }}
        target="_blank">
        {colValue}
      </Link>
    ) : "-";
  }

  render() {
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div>
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <Flex className="card w-full">

          <Flex className="w-full" column>
            <MonitoringMMFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => this.loadData()}
              />
              <Button
                icon="pi pi-external-link"
                iconPos="left"
                label="CSV"
                onClick={this.exportCsv} />
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                emptyMessage={'Tidak ada data untuk ditampilkan.'}
                scrollable={true}
                scrollHeight="1000px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
                ref={(el) => { this.tableExportData = el; }}
                footer={footer}>
                <Column
                  field="trackingSiteCity"
                  header="Kota"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="trackingSite"
                  header="Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="trackingSiteCode"
                  header="Kode Cabang"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="perwakilan"
                  header="Perwakilan"
                  filter={true}
                  filterMatchMode="contains"
                  sortable={true}
                  style={{ width: '200px', height: '40px' }}
                />
                <Column
                  field="prevOpenStatus"
                  header="Sisa Kemarin"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="do"
                  header="Masuk DO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="in"
                  header="Masuk Tanpa DO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="total"
                  header="Total Resi"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="out"
                  header="Total Resi Keluar"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="nob"
                  header="NOB"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="notSo"
                  header="Belum SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="doneSo"
                  header="Sudah SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="overSo"
                  header="Lebih SO"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
                <Column
                  field="problem"
                  header="Problem"
                  body={this.columnDetailLinkBody}
                  sortable={true}
                  style={{ width: '90px', height: '40px' }}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
