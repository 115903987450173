import React from 'react';
import Loader from 'react-loader-spinner'

export const getGeneralSummary = (summary) => {
  const rpuToday = [
    {
      title: "SPK",
      total: summary.rpu_pickup_today,
      filterValue: "rpu_pickup_today",
      filterTitle: "Request Pickup Hari ini",
    },
    {
      title: "Pick (Plan Drop + Pickup)",
      total: summary.rpu_pickup_today_picked_all,
      filterValue: "pick_all",
      filterTitle: "Request Pickup Hari ini - Pick (Plan Drop + Pickup)",
    },
    {
      title: "Pick",
      total: summary.rpu_pickup_today_picked,
      filterValue: "pick",
      filterTitle: "Request Pickup Hari ini - Pick",
    },
    {
      title: "Unpick",
      total: summary.rpu_pickup_today_unpicked,
      filterValue: "unpick",
      filterTitle: "Request Pickup Hari ini - Unpick",
    },
    {
      title: "Unpick Reschedule",
      total: summary.rpu_pickup_today_unpicked_reschedule,
      filterValue: "unpick_reschedule",
      filterTitle: "Request Pickup Hari ini - Unpick Reschedule",
    },
    {
      title: "Unpick Not Reschedule",
      total: summary.rpu_pickup_today_unpicked_not_reschedule,
      filterValue: "unpick_not_reschedule",
      filterTitle: "Request Pickup Hari ini - Unpick Not Reschedule",
    },
    {
      title: "Belum POP",
      total: summary.rpu_pickup_today_not_pop,
      filterValue: "not_pop",
      filterTitle: "Request Pickup Hari ini - Belum POP",
    },
    {
      title: "Belum Assign Sigesit",
      total: summary.rpu_pickup_today_not_assigned,
      filterValue: "not_assign",
      filterTitle: "Request Pickup Hari ini - Belum Assign Sigesit",
    },
  ];
  const spkYesterday = [
    {
      title: "SPK",
      total: summary.spk_yesterday,
      filterValue: "spk_yesterday",
      filterTitle: "SPK Kemarin",
    },
    {
      title: "Pick (Plan Drop + Pickup)",
      total: summary.spk_yesterday_picked_all,
      filterValue: "pick_all",
      filterTitle: "SPK Kemarin - Pick (Plan Drop + Pickup),"
    },
    {
      title: "Pick",
      total: summary.spk_yesterday_picked,
      filterValue: "pick",
      filterTitle: "SPK Kemarin - Pick",
    },
    {
      title: "Unpick",
      total: summary.spk_yesterday_unpicked,
      filterValue: "unpick",
      filterTitle: "SPK Kemarin - Unpick",
    },
    {
      title: "Unpick Reschedule",
      total: summary.spk_yesterday_unpicked_reschedule,
      filterValue: "unpick_reschedule",
      filterTitle: "SPK Kemarin - Unpick Reschedule",
    },
    {
      title: "Unpick Not Reschedule",
      total: summary.spk_yesterday_unpicked_not_reschedule,
      filterValue: "unpick_not_reschedule",
      filterTitle: "SPK Kemarin - Unpick Not Reschedule",
    },
    {
      title: "Belum POP",
      total: summary.spk_yesterday_not_pop,
      filterValue: "not_pop",
      filterTitle: "SPK Kemarin - Belum POP",
    },
    {
      title: "Belum Assign Sigesit",
      total: summary.spk_yesterday_not_assigned,
      filterValue: "not_assign",
      filterTitle: "SPK Kemarin - Belum Assign Sigesit",
    },
  ];
  const spk = [
    {
      title: "SPK Kemarin",
      total: summary.spk_yesterday,
      filterValue: "spk_yesterday",
      filterTitle: "SPK Kemarin",
    },
    {
      title: "SPK Hari Ini",
      total: summary.spk_today,
      filterValue: "spk_today",
      filterTitle: "SPK Hari Ini",
    },
    {
      title: "Total SPK",
      total: summary.spk,
      filterValue: "spk",
      filterTitle: "Total SPK",
    },
    {
      title: "Assign Sigesit",
      total: summary.assigned,
      filterValue: "assign",
      filterTitle: "Assign Sigesit",
    },
    {
      title: "Belum Assign Sigesit",
      total: summary.not_assigned,
      filterValue: "not_assign",
      filterTitle: "Detail SPK - Belum Assign Sigesit",
    },
    {
      title: "Pick (Plan Pickup + DROP)",
      total: summary.picked_all,
      filterValue: "pick_all",
      filterTitle: "Pick (Plan Pickup + DROP)",
    },
    {
      title: "Pick",
      total: summary.picked,
      filterValue: "pick",
      filterTitle: "Pick",
    },
    {
      title: "Unpick",
      total: summary.unpicked,
      filterValue: "unpick",
      filterTitle: "Unpick",
    },
    {
      title: "Unpick Reschedule",
      total: summary.unpicked_reschedule,
      filterValue: "unpick_reschedule",
      filterTitle: "Unpick Reschedule",
    },
    {
      title: "Unpick Not Reschedule",
      total: summary.unpicked_not_reschedule,
      filterValue: "unpick_not_reschedule",
      filterTitle: "Unpick Not Reschedule",
    },
    {
      title: "Auto POP",
      total: summary.auto_pop,
      filterValue: "auto_pop",
      filterTitle: "Auto POP",
    },
    {
      title: "Manifest",
      total: summary.manifested,
      filterValue: "manifest",
      filterTitle: "Manifest",
    },
    {
      title: "Pick/Drop Belum Manifest",
      total: summary.pick_not_manifested,
      filterValue: "pick_not_manifested",
      filterTitle: "Pick/Drop Belum Manifest",
    },
    {
      title: "Drop",
      total: summary.dropped,
      filterValue: "drop",
      filterTitle: "Drop",
    },
  ]
  const rpuPlan = [
    {
      title: "Pickup",
      total: summary.rpu_pickup,
      filterValue: "rpu_pickup",
      filterTitle: "Request Pickup Plan - Pickup",
    },
    {
      title: "Drop",
      total: summary.spk_today,
      filterValue: "rpu_drop",
      filterTitle: "Request Pickup Plan - Drop",
    },
  ]
  const actualPlan = [
    {
      title: "Pickup",
      total: summary.picked,
      filterValue: "pick_all",
      filterTitle: "Pick (Plan Pickup + Drop)",
    },
    {
      title: "Drop",
      total: summary.dropped,
      filterValue: "drop",
      filterTitle: "Drop",
    },
    {
      title: "Auto POP",
      total: summary.auto_pop,
      filterValue: "auto_pop",
      filterTitle: "Auto POP",
    },
  ]

  return {
    rpuToday: rpuToday,
    spkYesterday: spkYesterday,
    spk: spk,
    rpuPlan: rpuPlan,
    actualPlan: actualPlan,
  }
}

export const getUnpickSummary = (summaryUnpick) => {
  const { total, totalReschedule, totalUnreschedule, reschedules = [], unreschedules = [] } = summaryUnpick
  const unpickTotal = [
    {
      title: "Total Unpick",
      total: total,
      target: "total",
      filterValue: "unpick",
      filterTitle: `Unpick`
    },
    {
      title: "Total Unpick Reschedule",
      total: totalReschedule,
      target: "reschedule",
      filterValue: "unpick",
      filterTitle: "Unpick | Reschedule"
    },
    {
      title: "Total Unpick Unreschedule",
      total: totalUnreschedule,
      target: "unreschedule",
      filterValue: "unpick",
      filterTitle: "Unpick | Not Reschedule"
    },
  ]
  const unpickReschedule = reschedules.map((d) => (
    {
      title: d.reason_name,
      total: d.count,
      reasonId: d.reason_id,
      filterValue: "unpick",
      filterTitle: `Unpick | ${d.reason_name}`
    }
  ))
  const unpickNotReschedule = unreschedules.map((d) => (
    {
      title: d.reason_name,
      total: d.count,
      reasonId: d.reason_id,
      filterValue: "unpick",
      filterTitle: `Unpick | ${d.reason_name}`,
    }
  ))

  return {
    unpickTotal: unpickTotal,
    unpickReschedule: unpickReschedule,
    unpickNotReschedule: unpickNotReschedule,
  }
}

export const summaryList = (data, valuePreview, isLoading) => {
  return (
    <div>
      {isLoading && (
        <Loader
          type="ThreeDots"
          color="#c8c8c8"
          height="50"
          width="50"
        />
      )}
      {!isLoading && data && (
        data.length > 0 ? (
          data.map((d, index) => (
            <div key={index}>
              <span className="font-bold">{d.title}: </span>
              {valuePreview(d)}
            </div>
          ))
        ) : (
          <div><p>Tidak ada data</p></div>
        )
      )}
    </div>
  );
};
