import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import React, { RefObject } from 'react';
import { Panel } from 'primereact/panel';
import DropdownCity from "./../../components/dropdown/DropdownCity";
import DropdownRepresentative from "./../../components/dropdown/DropdownRepresentative";
import DropdownService from "./../../components/dropdown/DropdownService";
import { MM_BRANCH_TYPE_OPTIONS } from "./constants";


export interface IMonitoringMMFilterContainerValue {
  date?: any;
  city?: { value: string } | null;
  representative?: { value: string } | null;
  service?: { value: string } | null;
  cod?: any;
  branchType?: string;
}

export class MonitoringMMFilterContainer extends React.Component<any, any> {
  initialFormValues: IMonitoringMMFilterContainerValue = {
    date: new Date(),
    city: null,
    representative: null,
    service: null,
    cod: null,
    branchType: "",
  };
  refFormik: RefObject<Formik> = React.createRef();

  optionsCod = [
    {
      label: 'Semua',
      value: null,
    },
    {
      label: 'COD',
      value: true,
    },
    {
      label: 'NON COD',
      value: false,
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };

  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 p-4">
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Tanggal</label>
                  <Calendar
                    className="flex-1"
                    name="date"
                    readOnlyInput={true}
                    dateFormat="yy-mm-dd"
                    showWeek={true}
                    onBlur={formikProps.handleBlur}
                    onChange={formikProps.handleChange}
                    value={formikProps.values.date}
                  />
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Kota</label>
                  <div className="flex-1">
                    <DropdownCity
                      name='city'
                      onChange={formikProps.handleChange("city")}
                      value={formikProps.values.city}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Perwakilan</label>
                  <div className="flex-1">
                    <DropdownRepresentative
                      name='representative'
                      onChange={formikProps.handleChange("representative")}
                      value={formikProps.values.representative}
                    />
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Layanan</label>
                  <div className="flex-1">
                    <DropdownService
                      name='service'
                      onChange={formikProps.handleChange("service")}
                      value={formikProps.values.service}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">COD</label>
                  <Dropdown
                    className="flex-1"
                    options={this.optionsCod}
                    onChange={formikProps.handleChange('cod')}
                    value={formikProps.values.cod}
                  />
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Tipe Cabang</label>
                  <Dropdown
                    className="flex-1"
                    options={MM_BRANCH_TYPE_OPTIONS}
                    onChange={formikProps.handleChange('branchType')}
                    value={formikProps.values.branchType}
                  />
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
