import { observable } from "mobx";
import { observer } from "mobx-react";
import moment from "moment";
import { Column, ColumnProps } from "primereact/column";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import qs from "qs";
import React from "react";
import { Link } from 'react-router-dom';

import { DataSource } from "../../models/DataSource";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { ITimeWindowBranchDetailContainerQuery } from "./TimeWindowBranchDetailContainer";
import { ITimeWindowDriverDetailContainerQuery } from "./TimeWindowDriverDetailContainer";
import { ITimeWindowAwbDetailContainerQuery } from "./TimeWindowAwbDetailContainer";

@observer
export class TimeWindowBranchSummaryContainer extends React.Component<
any,
any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;
  data;
  dataSource = new DataSource<any>();
  loaded: boolean = false;

  filterParams: any;

  refDataTable;

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      filterCollapsed: false,
    };

    this.exportCsv = this.exportCsv.bind(this);
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;
    const fParams = filterParams;

    this.dataSource.loading = true;
    this.monitoringRestService
      .post("sf/time-window/branch-summary", fParams)
      .subscribe(
        (response) => {
          const mappedData = response.map((d) => {
            return {
              ...d,
              branch_id: d.BRANCH_ID,
              branch_name: d.BRANCH_NAME,
              branch_code: d.BRANCH_CODE,
              sigesit: d.SIGESIT,
              time_window_start_date: d.TIME_WINDOW_START_DATE,
              pickup_point: d.PICKUP_POINT,
              rpu: d.RPU,
              spk_yesterday: d.SPK_YESTERDAY,
              spk_today: d.SPK_TODAY,
              spk: d.SPK,
              assigned: d.ASSIGNED,
              not_assigned: d.NOT_ASSIGNED,
              picked_all: d.PICKED_ALL,
              picked: d.PICKED,
              pick_percentage: d.PICK_PERCENTAGE,
              unpicked: d.UNPICKED,
              unpick_percentage: d.UNPICK_PERCENTAGE,
              not_pop: d.NOT_POP,
              not_pop_percentage: d.NOT_POP_PERCENTAGE,
              auto_pop: d.AUTO_POP,
              auto_pop_percentage: d.AUTO_POP_PERCENTAGE,
              pop_on_time: d.POP_ON_TIME,
              pop_on_time_percentage: d.POP_ON_TIME_PERCENTAGE,
              pop_late_time: d.POP_LATE_TIME,
              pop_late_time_percentage: d.POP_LATE_TIME_PERCENTAGE,
              dropped: d.DROPPED,
              drop_percentage: d.DROP_PERCENTAGE,
              manifested: d.MANIFESTED,
              pick_not_manifested: d.PICK_NOT_MANIFESTED,
              oversla: d.OVERSLA,
            }
          });
          this.data = mappedData;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
          this.loaded = true;
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  async exportCsv() {
    this.setState({ exportExcelLoading: true });
    try {
      await this.refDataTable.exportCSV();
    } catch (error) {
      throw error;
    } finally {
      this.setState({ exportExcelLoading: false });
    }
  }

  branchDetailLink = (rowData, column) => {
    const params: ITimeWindowBranchDetailContainerQuery = {
      branchName: rowData.branch_name,
      branchId: rowData.branch_id,
      filters: this.filterParams,
    }
    return (
      <Link
        to={{
          pathname: '/admin/time-window/branch-detail',
          search: qs.stringify(params),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  };

  driverDetailLink = (rowData, column) => {
    const params: ITimeWindowDriverDetailContainerQuery = {
      branchName: rowData.branch_name,
      branchId: rowData.branch_id,
      filters: this.filterParams,
    }
    const timeWindowStartDate = rowData.time_window_start_date;
    if (timeWindowStartDate && moment(timeWindowStartDate).isValid()) {
      params.timeWindowStartDate = moment(timeWindowStartDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return (
      <Link
        to={{
          pathname: '/admin/time-window/driver-detail',
          search: qs.stringify(params),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  };

  awbDetailLink = (rowData, column, params) => {
    const qparams: ITimeWindowAwbDetailContainerQuery = {
      branchName: rowData.branch_name,
      branchId: rowData.branch_id,
      filters: this.filterParams,
      ...params,
    }
    return (
      <Link
        to={{
          pathname: '/admin/time-window/awb-detail',
          search: qs.stringify(qparams),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  };
  
  columnPerc = (rowData, column: ColumnProps) => {
    return rowData[column.field!] ? rowData[column.field!].toFixed(2) : 0;
  };

  getPerc = (obj) => {
    return obj ? obj.toFixed(2) : 0;
  };

  render() {
    const header = (
      <div style={{ textAlign: "left" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="CSV"
          onClick={this.exportCsv}
        ></Button>
      </div>
    );

    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <DataTable
          ref={(el) => (this.refDataTable = el)}
          emptyMessage={"Tidak ada data untuk ditampilkan."}
          scrollable={true}
          scrollHeight="550px"
          style={{ width: "100%" }}
          className="mt-4"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          header={header}
          footer={footer}
        >
          <Column
            field="branch_name"
            header="Cabang"
            filter={true}
            filterMatchMode="contains"
            sortable={true}
            style={{ width: "150px", "vertical-align": "text-top" }}
            body={this.branchDetailLink}
          />
          <Column
            field="branch_code"
            header="Kode Analytics Gerai"
            sortable={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />

          <Column
            field="sigesit"
            header="Si Gesit"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.driverDetailLink(row, column);
            }}
          />

          <Column
            field="pickup_point"
            header="Titik Pickup"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />

          <Column
            field="rpu"
            header="Plan Pickup + Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "rpu",
                title: "RPU (Pickup + Drop)",
              });
            }}
          />

          <Column
            field="spk_yesterday"
            header="SPK Kemarin"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "spk_yesterday",
                title: "SPK Kemarin",
              });
            }}
          />

          <Column
            field="spk_today"
            header="SPK Hari ini"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "spk_today",
                title: "SPK Hari Ini",
              });
            }}
          />

          <Column
            field="spk"
            header="Total SPK"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "spk",
                title: "Total SPK",
              });
            }}
          />
          <Column
            field="assigned"
            header="SPK Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "assign",
                title: "SPK Assign Sigesit",
              });
            }}
          />

          <Column
            field="not_assigned"
            header="SPK Belum Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "not_assign",
                title: "SPK Belum Assign Sigesit",
              });
            }}
          />

          <Column
            field="picked_all"
            header="Pick (Plan Pickup + Drop)"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pick_all",
                title: "Pick (Plan Pickup + Drop)",
              });
            }}
          />
          <Column
            field="picked"
            header="Pick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pick",
                title: "Pick",
              });
            }}
          />
          <Column
            field="pick_percentage"
            header="% Pick"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            body={this.columnPerc}
          />

          <Column
            field="unpicked"
            header="Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "unpick",
                title: "Unpick",
              });
            }}
          />
          <Column
            field="unpick_percentage"
            header="% Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />

          <Column
            field="not_pop"
            header="Belum POP"
            sortable={true}
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "not_pop",
                title: "Belum POP",
              });
            }}
          />
          <Column
            field="not_pop_percentage"
            header="% Belum POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />

          <Column
            field="auto_pop"
            header="Auto POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "auto_pop",
                title: "Auto POP",
              });
            }}
          />
          <Column
            field="auto_pop_percentage"
            header="% Auto POP"
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />

          <Column
            field="pop_on_time"
            header="POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pop_on_time",
                title: "POP On Time",
              });
            }}
          />
          <Column
            field="pop_on_time_percentage"
            header="% POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />

          <Column
            field="pop_late_time"
            header="POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pop_late_time",
                title: "POP Tidak On Time",
              });
            }}
          />

          <Column
            field="pop_late_time_percentage"
            header="% POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />

          <Column
            field="dropped"
            header="Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "drop",
                title: "Drop",
              });
            }}
          />
          <Column
            field="drop_percentage"
            header="% Drop"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            // style={{ width: "4vw" }}
            body={this.columnPerc}
          />

          <Column
            field="manifested"
            header="Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "manifest",
                title: "Manifest",
              });
            }}
          />

          <Column
            field="pick_not_manifested"
            header="Pick/Drop Belum Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pick_not_manifested",
                title: "Pick/Drop Belum Manifest",
              });
            }}
          />

          <Column
            field="oversla"
            header="Oversla 1x24"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "oversla",
                title: "Oversla 1x24",
              });
            }}
          />
        </DataTable>
      </div>
    );
  }
}
