import AsyncDropDown from './AsyncDropDown';
import {DependencyContainer} from "../../utils/DependencyInjection";
import { ApiKeyRestService } from '../../services/ApiKeyRestService';

class DropdownBranch extends AsyncDropDown {
    constructor(props: any) {
        super(props);
        const myState = {
            key: props.keyValue !== undefined ? props.keyValue : 'branch_code',
            limit: 50,
            page: 1,
            sortBy: props.sortBy !== undefined ? props.sortBy : 'branch_code',
            sortDir: props.sortDir !== undefined ? props.sortDir : 'asc',
            filters: props.filters !== undefined ? props.filters : {},
            isClearable: props.isClearable !== undefined ? props.isClearable : true,
            minimumKeyword: 3,
            method: 'GET',
            url: '/masterdata/branch',
            getResponseData: function (response) {
                return response.data;
            },
            placeholder:
                props.placeholder !== undefined
                    ? props.placeholder
                    : 'Pilih Cabang',
            template:
                props.template !== undefined
                    ? props.template
                    : '{{branch_name}} ({{branch_code}})',
            selectAll: props.selectAll !== undefined ? props.selectAll : false,
            selectAllLabel:
                props.selectAllLabel !== undefined
                    ? props.selectAllLabel
                    : 'Semua',
        };
        console.log("myState", myState)
        Object.assign(this.state, myState);
        this.apiService = DependencyContainer.get(ApiKeyRestService);
    }
}

export default DropdownBranch;
