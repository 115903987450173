export const MM_BRANCH_TYPE_OPTIONS = [
  {
    label: 'Semua',
    value: '',
  },
  {
    label: 'Hub',
    value: 'hub',
  },
  {
    label: 'Line Haul',
    value: 'linehaul',
  },
  {
    label: 'Sortation',
    value: 'sortation',
  },
];