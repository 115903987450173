import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Fieldset } from 'primereact/fieldset';
import { Growl } from 'primereact/growl';
import qs from 'qs';
import React, { RefObject } from 'react';

import { DataSource } from '../../models/DataSource';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbDetailModalComponent } from './../awb/AwbDetailModalComponent';
import { ITimeWindowFilterContainerValue } from "./TimeWindowFilterContainer";
import { getUnpickSummary, summaryList } from './TimeWindowSummaryList';

export interface ITimeWindowAwbDetailContainerQuery {
  status: string;
  title: string;
  branchName?: string;
  branchId?: string;
  employeeId?: string;
  employeeName?: string;
  timeWindowStartDate?: string;
  filters: ITimeWindowFilterContainerValue;
}

@observer
export class TimeWindowAwbDetailContainer extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  @observable data;
  tableExportData;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  

  queryParams;
  title;
  branchName;
  employeeName;

  constructor(props, context) {
    super(props, context);
    this.state = {
      isLoadingSummaryUnpick: false,
      unpickTotalCollapsed: false,
      unpickRescheduleCollapsed: false,
      unpickNotRescheduleCollapsed: false,
      unpickTotal: null,
      unpickReschedule: null,
      unpickNotReschedule: null,
    };
    this.export = this.export.bind(this);
  }

  componentDidMount() {
    this.queryParams = qs.parse(
      this.props.history.location.search.substr(1),
    ) as any;

    const { title = "", branchName = "", employeeName = "", status = "" } = this.queryParams
    this.title = title;
    this.branchName = branchName;
    this.employeeName = employeeName;
    
    this.loadData(this.queryParams);
    if (status === "unpick") {
      this.loadUnpickData();
    }
  }
  
  loadData(filter) {
    this.dataSource.loading = true;
    const { branchId, branchName, employeeId, employeeName, status, title, timeWindowStartDate, filters, unpickReasonId, target } = filter
    const params = {
      ...filters,
      branchId: branchId,
      branchName: branchName,
      employeeId: employeeId,
      employeeName: employeeName,
      status: status,
      title: title,
      timeWindowStartDate: timeWindowStartDate,
      unpick_reason_id: unpickReasonId || null,
      unpick_reschedule: ["reschedule", "unreschedule"].includes(target) ? target === "reschedule" : null,
    }
    this.monitoringRestService
      .post('sf/time-window/branch-summary/detail', params)
      .subscribe(
        response => {
          const mappedData = response.map((d) => {
            return {
              ...d,
              work_order_id: d.WORK_ORDER_ID,
              awb_number: d.AWB_NUMBER,
              pickup_request_detail_id: d.PICKUP_REQUEST_DETAIL_ID,
              work_order_status_id_last: d.WORK_ORDER_STATUS_ID_LAST,
              last_status_date: d.LAST_STATUS_DATE,
              picked_date: d.PICKED_DATE,
              pop_date: d.POP_DATE,
              pop_status_name: d.POP_STATUS_NAME,
              pickup_date_time: d.PICKUP_DATE_TIME,
              updated_time: d.UPDATED_TIME,
              status_name: d.STATUS_NAME,
              unpicked_reason_name: d.UNPICKED_REASON_NAME,
              partner_name: d.PARTNER_NAME,
              pickup_request_name: d.PICKUP_REQUEST_NAME,
              pickup_request_address: d.PICKUP_REQUEST_ADDRESS,
              pickup_schedule_date_time: d.PICKUP_SCHEDULE_DATE_TIME,
              ori_pickup_schedule_date_time: d.ORI_PICKUP_SCHEDULE_DATE_TIME,
              merchant_pickup_schedule_date_time: d.MERCHANT_PICKUP_SCHEDULE_DATE_TIME,
              pickup_sla_date_time: d.PICKUP_SLA_DATE_TIME,
              driver_nik: d.DRIVER_NIK,
              driver_name: d.DRIVER_NAME,
              branch_assign_date: d.BRANCH_ASSIGN_DATE,
              driver_assign_date: d.DRIVER_ASSIGN_DATE,
              branch_name: d.BRANCH_NAME,
              scheduled_input_date_time: d.SCHEDULED_INPUT_DATE_TIME,
              reschedule_type: d.RESCHEDULE_TYPE,
              time_window_str: d.TIME_WINDOW_STR,
              time_window_start_date: d.TIME_WINDOW_START_DATE,
              time_window_end_date: d.TIME_WINDOW_END_DATE,
              is_auto_pop: d.IS_AUTO_POP,
              is_manifested: d.IS_MANIFESTED,
              manifested_date: d.MANIFESTED_DATE,
              pickup_request_type: d.PICKUP_REQUEST_TYPE,
              gabung_paket: d.GABUNG_PAKET,
              out_first_mile: d.OUT_FIRST_MILE,
              oversla: d.OVERSLA,
            }
          })
          this.data = mappedData;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
          this.title = title;
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
        () => {
          this.dataSource.loading = false;
        }
      )
  }

  loadUnpickData() {
    this.setState({ isLoadingSummaryUnpick: true });
    const { branchId, filters, unpickReasonId } = this.queryParams
    const params = {
      ...filters,
      branchId: branchId,
      unpick_reason_id: unpickReasonId,
    }
    this.monitoringRestService
      .post('sf/time-window/summary/unpick', params)
      .subscribe(
        response => {
          const { reschedules = [], unreschedules = [] } = response
          const mappedData = {
            ...response,
            reschedules: reschedules.map((d) => {
              return {
                ...d,
                unpicked_not_reschedule: d.UNPICKED_NOT_RESCHEDULE,
                reason_id: d.REASON_ID,
                reason_name: d.REASON_NAME,
                count: d.COUNT,
              }
            }),
            unreschedules: unreschedules.map((d) => {
              return {
                ...d,
                unpicked_not_reschedule: d.UNPICKED_NOT_RESCHEDULE,
                reason_id: d.REASON_ID,
                reason_name: d.REASON_NAME,
                count: d.COUNT,
              }
            }),
          }
          const unpickSummary = getUnpickSummary(mappedData)
          const { unpickTotal, unpickReschedule = [], unpickNotReschedule = [] } = unpickSummary
          this.setState({
            unpickTotal: unpickTotal,
            unpickReschedule: unpickReschedule,
            unpickNotReschedule: unpickNotReschedule,
          })
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
        () => {
          this.setState({ isLoadingSummaryUnpick: false });
        }
      )
  }

  export() {
    this.tableExportData.exportCSV();
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  }

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>{rowData[column.field!]}</a>
    );
  }

  columnDateTimeFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!])).format("DD-MM-YYYY HH:mm")
      : "";
  };

  unpickSummaryTotalDetailLink = (data) => {
    const params = {
      ...this.queryParams,
      status: data.filterValue,
      title: data.filterTitle,
      target: data.target,
      unpickReasonId: null,
    }
    return (
      <a href="#" onClick={() => this.loadData(params)}>
        {data.total.toLocaleString()}
      </a>
    )
  }
  
  unpickSummaryDetailLink = (data) => {
    const params = {
      ...this.queryParams,
      status: data.filterValue,
      title: data.filterTitle,
      unpickReasonId: data.reasonId,
      target: null,
    }
    return (
      <a href="#" onClick={() => this.loadData(params)}>
        {data.total.toLocaleString()}
      </a>
    )
  }

  render() {
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div className="card">
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <div className="row">
          <div className="col-md-6">
            <h3>{this.title}</h3>
            <h5>{this.branchName}</h5>
            <h5>{this.employeeName}</h5>
          </div>
          <div className="col-md-6">
            <Button 
              className="float-right mt-4"
              icon="pi pi-external-link"
              onClick={this.export}
              label="Export to CSV"
            />
          </div>
        </div>


        {this.queryParams && this.queryParams.status === 'unpick' && (
          <div className="row">
            <div className="col-md-4">
              <Fieldset legend="Summary" toggleable={true} collapsed={this.state.unpickTotalCollapsed} onToggle={(e) => this.setState({ unpickTotalCollapsed: e.value })}>
                {summaryList(this.state.unpickTotal, this.unpickSummaryTotalDetailLink, this.state.isLoadingSummaryUnpick)}
              </Fieldset>
            </div>
            <div className="col-md-4">
              <Fieldset legend="Unpick Reschedule" toggleable={true} collapsed={this.state.unpickRescheduleCollapsed} onToggle={(e) => this.setState({ unpickRescheduleCollapsed: e.value })}>
                {summaryList(this.state.unpickReschedule, this.unpickSummaryDetailLink, this.state.isLoadingSummaryUnpick)}
              </Fieldset>
            </div>
            <div className="col-md-4">
              <Fieldset legend="Unpick Not Reschedule" toggleable={true} collapsed={this.state.unpickNotRescheduleCollapsed} onToggle={(e) => this.setState({ unpickNotRescheduleCollapsed: e.value })}>
                {summaryList(this.state.unpickNotReschedule, this.unpickSummaryDetailLink, this.state.isLoadingSummaryUnpick)}
              </Fieldset>
            </div>
          </div>
        )}

        <DataTable
          emptyMessage={'Tidak ada data untuk ditampilkan.'}
          scrollable={true}
          scrollHeight="550px"
          className="mt-6"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
          footer={footer}
          ref={(el) => { this.tableExportData = el; }}>
          <Column
            field="awb_number"
            filter
            filterMatchMode="contains"
            sortable={true}
            header="No Resi"
            body={this.columnAwbNumber}
            style={{ width: "150px", "vertical-align": "text-top" }}
          />
          <Column
            field="partner_name"
            header="Partner"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="pickup_request_name"
            header="Merchant"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="pickup_request_address"
            header="Alamat"
            sortable={true}
            style={{
              width: "260px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="driver_name"
            header="SiGesit"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="driver_nik"
            header="SiGesit NIK"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="branch_name"
            header="Nama Cabang"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="driver_assign_date"
            header="Tgl Assign SiGesit"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateTimeFormat}
          />
          <Column
            field="branch_assign_date"
            header="Tgl Assign Cabang"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateTimeFormat}
          />
          <Column
            field="pickup_request_type"
            header="Plan Pickup Method"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="ori_pickup_schedule_date_time"
            header="Tgl Req. Pickup"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateTimeFormat}
          />
          <Column
            field="pickup_schedule_date_time"
            header="Tgl Schedule Pickup"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateTimeFormat}
          />

          <Column
            field="pickup_sla_date_time"
            header="SLA Pickup"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateTimeFormat}
          />
          <Column
            field="merchant_pickup_schedule_date_time"
            header="Merchant Scheduler"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="time_window_str"
            header="Time Window"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="pop_date"
            header="TGL POP"
            sortable={true}
            filter={false}
            body={this.columnDateTimeFormat}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="pop_status_name"
            header="Status POP"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="unpicked_reason_name"
            header="Reason"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />

          <Column
            field="status_name"
            header="Status Saat Ini"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="updated_time"
            header="Tgl Status Saat ini"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateTimeFormat}
          />
          <Column
            field="is_auto_pop"
            header="Auto POP"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
          />
          <Column
            field="is_manifested"
            header="Manifested"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
          />
          <Column
            field="manifested_date"
            header="TGL Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateTimeFormat}
          />
          <Column
            field="gabung_paket"
            header="Gabung Paket"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="out_first_mile"
            header="Out First Mile"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="oversla"
            header="Oversla 1x24"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
        </DataTable>
      </div>
    );
  }
}
