import { observable } from 'mobx';
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Growl } from 'primereact/growl';
import qs from 'qs';
import React from 'react';
import { RouterProps } from 'react-router';
import { Link } from 'react-router-dom';

import { DataSource } from '../../models/DataSource';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { ITimeWindowFilterContainerValue } from "./TimeWindowFilterContainer";
import { ITimeWindowDriverDetailContainerQuery } from "./TimeWindowDriverDetailContainer";
import { ITimeWindowAwbDetailContainerQuery } from "./TimeWindowAwbDetailContainer";

export interface ITimeWindowBranchDetailContainerQuery {
  branchName: string;
  branchId: string;
  filters: ITimeWindowFilterContainerValue;
}

@observer
export class TimeWindowBranchDetailContainer extends React.Component<RouterProps> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  growl: any;

  @observable data;
  tableExportData;
  dataSource = new DataSource<any>();

  queryParams;
  branchId;
  branchName;

  constructor(props, context) {
    super(props, context);
    this.export = this.export.bind(this);
  }

  componentDidMount() {
    this.queryParams = qs.parse(
      this.props.history.location.search.substr(1),
    ) as any;

    const { branchId, branchName } = this.queryParams
    this.branchId = branchId;
    this.branchName = branchName;
    
    this.loadData();
  }
  
  loadData() {
    this.dataSource.loading = true;
    const { branchId, branchName, filters } = this.queryParams
    const params = {
      ...filters,
      branchId: branchId,
      branchName: branchName,
    }
    this.monitoringRestService
      .post('sf/time-window/one-branch-summary', params)
      .subscribe(
        response => {
          const mappedData = response.map((d) => {
            return {
              ...d,
              partner_id: d.PARTNER_ID,
              branch_name: d.BRANCH_NAME,
              branch_code: d.BRANCH_CODE,
              capacity_pickup: d.CAPACITY_PICKUP,
              percent_pickup_volume: d.PERCENT_PICKUP_VOLUME,
              branch_id: d.BRANCH_ID,
              time_window_start_date: d.TIME_WINDOW_START_DATE,
              time_window_end_date: d.TIME_WINDOW_END_DATE,
              sigesit: d.SIGESIT,
              pickup_point: d.PICKUP_POINT,
              rpu: d.RPU,
              rpu_pickup: d.RPU_PICKUP,
              rpu_drop: d.RPU_DROP,
              spk_yesterday: d.SPK_YESTERDAY,
              spk_today: d.SPK_TODAY,
              spk: d.SPK,
              assigned: d.ASSIGNED,
              not_assigned: d.NOT_ASSIGNED,
              picked: d.PICKED,
              picked_all: d.PICKED_ALL,
              unpicked: d.UNPICKED,
              unpicked_reschedule: d.UNPICKED_RESCHEDULE,
              unpicked_not_reschedule: d.UNPICKED_NOT_RESCHEDULE,
              pop: d.POP,
              not_pop: d.NOT_POP,
              pick_on_time: d.PICK_ON_TIME,
              pick_late_time: d.PICK_LATE_TIME,
              pop_on_time: d.POP_ON_TIME,
              pop_late_time: d.POP_LATE_TIME,
              auto_pop: d.AUTO_POP,
              dropped: d.DROPPED,
              transfer: d.TRANSFER,
              cancel: d.CANCEL,
              manifested: d.MANIFESTED,
              pick_not_manifested: d.PICK_NOT_MANIFESTED,
              unfinish: d.UNFINISH,
              scheduled: d.SCHEDULED,
              scheduled_input: d.SCHEDULED_INPUT,
              oversla: d.OVERSLA,
              drop_percentage: d.DROP_PERCENTAGE,
              pick_percentage: d.PICK_PERCENTAGE,
              unpick_percentage: d.UNPICK_PERCENTAGE,
              pop_percentage: d.POP_PERCENTAGE,
              not_pop_percentage: d.NOT_POP_PERCENTAGE,
              pick_on_time_percentage: d.PICK_ON_TIME_PERCENTAGE,
              pick_late_time_percentage: d.PICK_LATE_TIME_PERCENTAGE,
              pop_on_time_percentage: d.POP_ON_TIME_PERCENTAGE,
              pop_late_time_percentage: d.POP_LATE_TIME_PERCENTAGE,
              auto_pop_percentage: d.AUTO_POP_PERCENTAGE,
            }
          })
          this.data = mappedData;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Terjadi kesalahan",
            detail: "Terjadi kesalahan pada sistem, coba beberapa saat lagi!",
          };
          this.growl.show(msg);
        },
      )
      .add(() => {
        this.dataSource.loading = false;
      });
  }

  export() {
    this.tableExportData.exportCSV();
  }

  driverDetailLink = (rowData, column) => {
    const params: ITimeWindowDriverDetailContainerQuery = {
      branchName: rowData.branch_name,
      branchId: this.branchId,
      filters: this.queryParams.filters,
    }
    const timeWindowStartDate = rowData.time_window_start_date;
    if (timeWindowStartDate && moment(timeWindowStartDate).isValid()) {
      params.timeWindowStartDate = moment(timeWindowStartDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return (
      <Link
        to={{
          pathname: '/admin/time-window/driver-detail',
          search: qs.stringify(params),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  };

  awbDetailLink = (rowData, column, params) => {
    const qparams: ITimeWindowAwbDetailContainerQuery = {
      branchName: this.branchName,
      branchId: this.branchId,
      filters: this.queryParams.filters,
      ...params,
    }
    const timeWindowStartDate = rowData.time_window_start_date;
    if (timeWindowStartDate && moment(timeWindowStartDate).isValid()) {
      qparams.timeWindowStartDate = moment(timeWindowStartDate).format("YYYY-MM-DD HH:mm:ss");
    }
    return (
      <Link
        to={{
          pathname: '/admin/time-window/awb-detail',
          search: qs.stringify(qparams),
        }}
        target="_blank">
        {rowData[column.field!]}
      </Link>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!])).format("DD-MM-YYYY")
      : "";
  };

  columnPerc = (rowData, column: ColumnProps) => {
    return rowData[column.field!] ? rowData[column.field!].toFixed(2) : 0;
  };

  render() {
    const header = <div style={{ textAlign: 'left' }}><Button type="button" icon="pi pi-external-link" iconPos="left" label="CSV" onClick={this.export}></Button></div>;
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div className="card">
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <h3>{this.branchName}</h3>

        <DataTable
          emptyMessage={'Tidak ada data untuk ditampilkan.'}
          scrollable={true}
          scrollHeight="550px"
          className="mt-6"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          paginator={true} rows={50} rowsPerPageOptions={[5, 10, 20, 50, 100]}
          footer={footer}
          header={header} ref={(el) => { this.tableExportData = el; }}>
          <Column
            field="branch_name"
            header="Cabang"
            filter={true}
            filterMatchMode="contains"
            sortable={true}
            style={{ width: "150px", "vertical-align": "text-top" }}
          />
          <Column
            field="branch_code"
            header="Kode Analytics Gerai"
            filter={true}
            filterMatchMode="contains"
            style={{ width: "150px", "vertical-align": "text-top" }}
          />
          <Column
            field="time_window_start_date"
            header="Pickup Schedule"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnDateFormat}
          />
          <Column
            field="time_window_start_date"
            header="Time Window"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              const timeWindowStartDate = row["time_window_start_date"];
              const timeWindowEndDate = row["time_window_end_date"];
              if (timeWindowStartDate && timeWindowEndDate) {
                return `${moment(timeWindowStartDate).hour()} - ${moment(
                  timeWindowEndDate
                ).hour()}`;
              }
              return "-";
            }}
          />
          <Column
            field="sigesit"
            header="Sigesit"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.driverDetailLink(row, column);
            }}
          />
          <Column
            field="pickup_point"
            header="Titik Pickup"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />
          <Column
            field="rpu"
            header="Plan Pickup + Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "rpu",
                title: "RPU (Pickup + Drop)",
              });
            }}
          />
          <Column
            field="spk_yesterday"
            header="SPK Kemarin"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "spk_yesterday",
                title: "SPK Kemarin",
              });
            }}
          />
          <Column
            field="spk_today"
            header="SPK Hari ini"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "spk_today",
                title: "SPK Hari Ini",
              });
            }}
          />
          <Column
            field="spk"
            header="Total SPK"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "spk",
                title: "Total SPK",
              });
            }}
          />
          <Column
            field="assigned"
            header="SPK Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "assign",
                title: "SPK Assign Sigesit",
              });
            }}
          />
          <Column
            field="not_assigned"
            header="SPK Belum Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "not_assign",
                title: "SPK Belum Assign Sigesit",
              });
            }}
          />
          <Column
            field="picked_all"
            header="Pick (Plan Pickup + Drop)"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pick_all",
                title: "Pick (Plan Pickup + Drop)",
              });
            }}
          />
          <Column
            field="picked"
            header="Pick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pick",
                title: "Pick",
              });
            }}
          />
          <Column
            field="pick_percentage"
            header="% Pick"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            body={this.columnPerc}
          />
          <Column
            field="unpicked"
            header="Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "unpick",
                title: "Unpick",
              });
            }}
          />
          <Column
            field="unpick_percentage"
            header="% Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />
          <Column
            field="not_pop"
            header="Belum POP"
            sortable={true}
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "not_pop",
                title: "Belum POP",
              });
            }}
          />
          <Column
            field="not_pop_percentage"
            header="% Belum POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />
          <Column
            field="auto_pop"
            header="Auto POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "auto_pop",
                title: "Auto POP",
              });
            }}
          />
          <Column
            field="auto_pop_percentage"
            header="% Auto POP"
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />
          <Column
            field="pop_on_time"
            header="POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pop_on_time",
                title: "POP On Time",
              });
            }}
          />
          <Column
            field="pop_on_time_percentage"
            header="% POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />
          <Column
            field="pop_late_time"
            header="POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pop_late_time",
                title: "POP Tidak On Time",
              });
            }}
          />
          <Column
            field="pop_late_time_percentage"
            header="% POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={this.columnPerc}
          />
          <Column
            field="dropped"
            header="Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "drop",
                title: "Drop",
              });
            }}
          />
          <Column
            field="drop_percentage"
            header="% Drop"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            // style={{ width: "4vw" }}
            body={this.columnPerc}
          />
          <Column
            field="manifested"
            header="Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "manifest",
                title: "Manifest",
              });
            }}
          />
          <Column
            field="pick_not_manifested"
            header="Pick/Drop Belum Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.awbDetailLink(row, column, {
                status: "pick_not_manifested",
                title: "Pick/Drop Belum Manifest",
              });
            }}
          />
        </DataTable>
      </div>
    );
  }
}
