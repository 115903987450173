import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import React, { RefObject } from 'react';
import { observer } from 'mobx-react';
import { Panel } from 'primereact/panel';
import DropdownPartner from "./../../components/dropdown/DropdownPartner";
import DropdownProvince from "./../../components/dropdown/DropdownProvince";
import DropdownCity from "./../../components/dropdown/DropdownCity";
import DropdownBranch from "./../../components/dropdown/DropdownBranch";

export interface ITimeWindowFilterContainerValue {
  date?: Date;
  spkTimeWindow?: any;
  isSpkToday?: any;
  partner?: any;
  province?: any;
  city?: any;
  branch?: any;
}

@observer
export class TimeWindowFilterContainer extends React.Component<any, any> {
  initialFormValues: ITimeWindowFilterContainerValue = {
    date: new Date(),
    spkTimeWindow: '',
    isSpkToday: '',
    partner: null,
    province: null,
    city: null,
    branch: null,
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionYN = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Ya',
      value: 'Y',
    },
    {
      label: 'Tidak',
      value: 'N',
    },
  ];

  optionTimeWindow = [
    { label: "Semua Jadwal", value: "", selected: true },
    { label: "08:00 - 09:00", value: "8" },
    { label: "09:00 - 10:00", value: "9" },
    { label: "10:00 - 11:00", value: "10" },
    { label: "11:00 - 12:00", value: "11" },
    { label: "12:00 - 13:00", value: "12" },
    { label: "13:00 - 14:00", value: "13" },
    { label: "14:00 - 15:00", value: "14" },
    { label: "15:00 - 16:00", value: "15" },
    { label: "16:00 - 17:00", value: "16" },
    { label: "17:00 - 18:00", value: "17" },
    { label: "18:00 - 19:00", value: "18" },
    { label: "19:00 - 20:00", value: "19" },
    { label: "20:00 - 21:00", value: "20" },
    { label: "21:00 - 22:00", value: "21" },
    { label: "22:00 - 06:00", value: "22" },
  ];

  constructor(props, context) {
    super(props, context);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    if (this.refFormik.current) {
      const newFormValues = Object.assign({}, this.refFormik.current.state.values, values);
      this.refFormik.current!.setValues(newFormValues);
      this.initialFormValues = newFormValues;
      return newFormValues;
    }

    return null;
  }

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          // enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => {}}>
          {(formikProps) => (
            <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-8 p-4">
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Tanggal</label>
                  <div className="flex-1">
                    <Calendar
                      name="date"
                      readOnlyInput={true}
                      dateFormat="yy-mm-dd"
                      showWeek={true}
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange('date')}
                      value={formikProps.values.date}
                    />                   
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Jam SPK</label>
                  <div className="flex-1">
                    <Dropdown
                      options={this.optionTimeWindow}
                      onChange={formikProps.handleChange('spkTimeWindow')}
                      value={formikProps.values.spkTimeWindow}
                    />    
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">SPK Hari Ini</label>
                  <div className="flex-1">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange('isSpkToday')}
                      value={formikProps.values.isSpkToday}
                    />                  
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Partner</label>
                  <div className="flex-1">
                    <DropdownPartner
                      name='partner'
                      onChange={formikProps.handleChange("partner")}
                      value={formikProps.values.partner}
                      keyValue="id"
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col gap-8">
                <div className="flex items-center">
                  <label className="flex-none w-48">Provinsi</label>
                  <div className="flex-1">
                    <DropdownProvince
                      name='province'
                      onChange={formikProps.handleChange("province")}
                      value={formikProps.values.province}
                      keyValue="id"
                    />                   
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Kota</label>
                  <div className="flex-1">
                    <DropdownCity
                      name='city'
                      onChange={formikProps.handleChange("city")}
                      value={formikProps.values.city}
                      keyValue="id"
                    />                   
                  </div>
                </div>
                <div className="flex items-center">
                  <label className="flex-none w-48">Cabang</label>
                  <div className="flex-1">
                    <DropdownBranch
                      name='branch'
                      onChange={formikProps.handleChange("branch")}
                      value={formikProps.values.branch}
                      keyValue="id"
                    />                   
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </Panel>
    );
  }
}
