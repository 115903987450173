import { observer } from 'mobx-react';
import React, { RefObject } from 'react';

import { TimeWindowFilterContainer } from './TimeWindowFilterContainer';
import { TimeWindowSummaryContainer } from './TimeWindowSummaryContainer';
import { TimeWindowBranchSummaryContainer } from './TimeWindowBranchSummaryContainer';
import { TabView, TabPanel } from 'primereact/tabview';
import { Flex } from 'reflexbox';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import moment from "moment";


@observer
export class TimeWindowContainer extends React.Component<any, any> {
  growl;
  refSummary: RefObject<TimeWindowSummaryContainer> = React.createRef();
  refBranchSummary: RefObject<TimeWindowBranchSummaryContainer> = React.createRef();
  refFilter: RefObject<TimeWindowFilterContainer> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      activeTabIndex: 1,
    };
  }

  loadData() {
    const formValues = this.refFilter.current!.formValues;

    const { date, spkTimeWindow, isSpkToday, partner, province, city, branch } = formValues;
    const params = {
      startDate: moment(date).format("YYYY-MM-DD"),
      rpuPickupToday: isSpkToday,
      timeWindowStartHour: spkTimeWindow,
      partnerId: partner ? partner.value : "",
      provinceId: province ? province.value : "",
      cityId: city ? city.value : "",
      branchId: branch ? branch.value : "",
    }
    if ((this.state as any).activeTabIndex === 1) {
      this.refBranchSummary.current!.loadData(params);
    } else {
      this.refSummary.current!.loadData(params);
    }
  }

  render() {
    return (
      <div>
        <Growl ref={el => (this.growl = el)} style={{ marginTop: '75px' }} />

        <div>
          <Flex className="card w-full">
            <Flex className="w-full" column>
              <TimeWindowFilterContainer ref={this.refFilter} />
              <br />
              <Flex>
                <Button
                  className="p-button-success mr-2"
                  label="Tampilkan"
                  onClick={() => this.loadData()}
                />
              </Flex>
            </Flex>
          </Flex>
        </div>
        <div>
          <TabView activeIndex={this.state.activeTabIndex} onTabChange={(e) => this.setState({ activeTabIndex: e.index })}>
            <TabPanel header="Summary">
              <TimeWindowSummaryContainer ref={this.refSummary} />
            </TabPanel>
            <TabPanel header="Detail">
              <TimeWindowBranchSummaryContainer ref={this.refBranchSummary} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}
